import './App.css';
import { Chat, Files, Posts, WeavyClient, WeavyProvider } from '@weavy/uikit-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import "@weavy/uikit-react/dist/css/weavy.css";
function App() {

  var client = new WeavyClient({ url: "https://confluence-demo.weavy.io", tokenFactory: () => "wyu_Ovh4hrY9JMduv6gIsRB3eNiQG6VWf601T5r0" })

  return (
    <WeavyProvider client={client} options={{ confluenceAuthenticationUrl: "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=NWdIwd9uwF0FVdqN1HlDuBlNy0jat8lM&scope=offline_access%20read%3Aspace-details%3Aconfluence%20read%3Apage%3Aconfluence%20read%3Acontent%3Aconfluence%20read%3Aspace%3Aconfluence%20read%3Acontent-details%3Aconfluence&redirect_uri=https%3A%2F%2Fconfluence-demo.weavy.io%2Fconfluence%2Fauth&response_type=code&prompt=consent" }}>
      <Container >
        <Row>
          <Col>
            <Navbar expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand href="#home">
                  <img
                    alt=""
                    src="logo192.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  />{' '}
                  Site B
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="#home">Service</Nav.Link>
                    <Nav.Link href="#home">Management</Nav.Link>
                    <Nav.Link href="#home">Users</Nav.Link>
                    <Nav.Link href="#home">Orders</Nav.Link>
                    <Nav.Link href="#link" active={true}>Products</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Nav className="flex-column">
              <Nav.Link href="#a">Product A</Nav.Link>
              <Nav.Link href="#b">Product B</Nav.Link>
              <Nav.Link href="#c" style={{ color: 'gray' }}>Product C</Nav.Link>
              <Nav.Link href="#d">Product D</Nav.Link>

            </Nav>
          </Col>
          <Col>
            <h1>Product C</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce felis nisi, consequat sed lorem sed, lacinia porttitor arcu. Cras luctus tincidunt sapien, id consectetur tellus facilisis sed.
            </p>
            <p>
              Quisque blandit velit ut est porttitor sagittis. Etiam in erat orci. Maecenas in mauris ac nisl tristique laoreet vel at nisi.
            </p>

            <Tabs
              defaultActiveKey="chat"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="files" title="Product files">
                <Files uid="filesapp" />
              </Tab>
              <Tab eventKey="chat" title="Chat">
                <div style={{ height: '600px' }}>
                  <Chat uid="chat-product-c" />
                </div>

              </Tab>
            </Tabs>
          </Col>
        </Row>

      </Container>
    </WeavyProvider>

  );
}

export default App;
